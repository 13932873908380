import { colors, display } from 'themes'

import { Text } from '@app/components/Text'
import styled from 'styled-components'

const ConfirmCodModalContent = styled.div`
  padding: 20px;
  ${display.flex('left', 'flex-start', 'column')};
`
const CodAmountText = styled(Text)`
  color: ${colors.SecondaryColor};
  font-size: 24px;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 36px;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 10px;
  align-self: end;
`

export { ButtonContainer, ConfirmCodModalContent, CodAmountText }
