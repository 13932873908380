import { ButtonContainer, CodAmountText, ConfirmCodModalContent } from '@app/components/ConfirmCodModal/styles'

import { CustomButton } from '@app/components/CustomButton'
import { CustomModal } from '@app/components/CustomModal'
import { FormatOrderCostWithCurrencyCode } from 'utils/OrderUtils'
import { MODALS } from '@app/containers/Collect/constants'
import PropTypes from 'prop-types'
import React from 'react'
import { Text } from '@app/components/Text'
import _ from 'lodash'

export const ConfirmCodModal = props => {
  const { intl, country, showModal, closeModal, selectedRecord, handleConfirmCod } = props
  return (
    <CustomModal
      title={intl.formatMessage({ id: 'collect_cod_payment' })}
      visible={showModal === MODALS.confirmCod}
      onCancel={closeModal}
    >
      <ConfirmCodModalContent>
        <Text
          textId='collect_cod_confirm_message'
          values={{ id: _.get(selectedRecord, 'trackingId', null), name: _.get(selectedRecord, 'toName', null) }}
        />
        <CodAmountText>{FormatOrderCostWithCurrencyCode(country, _.get(selectedRecord, 'cod', 0.0))}</CodAmountText>
        <ButtonContainer>
          <CustomButton
            type='tertiary'
            size='small'
            textId='payment_refused'
            dataTestId='payment-refused'
            onClick={closeModal}
          />
          <CustomButton
            dataTestId='confirmCodButton'
            textId='confirm'
            size='small'
            type='secondary'
            onClick={handleConfirmCod}
          />
        </ButtonContainer>
      </ConfirmCodModalContent>
    </CustomModal>
  )
}

ConfirmCodModal.propTypes = {
  showModal: PropTypes.number,
  closeModal: PropTypes.func,
  country: PropTypes.string,
  selectedRecord: PropTypes.object,
  handleConfirmCod: PropTypes.func
}

ConfirmCodModal.defaultProps = {
  showModal: null,
  closeModal: () => {},
  country: '',
  selectedRecord: {},
  handleConfirmCod: () => {}
}

export default ConfirmCodModal
