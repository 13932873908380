// @flow

import type { PostCollectOrderCodFailureResponse, PostCollectOrderCodSuccessResponse } from '@app/types/PostCollectOrderResponse'

import type { PostCollectOrderCodRequestPayload } from '@app/types/PostCollectOrderCodRequestPayload'
import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

export default (
  country: string,
  payload: PostCollectOrderCodRequestPayload
): Promise<PostCollectOrderCodSuccessResponse | PostCollectOrderCodFailureResponse> => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/orders/collect/search`
  return client.post(url, payload)
}
