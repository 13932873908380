// @flow

import { getShipperByExternalIdApi, getShipperByIdApi } from './ShipperApi/getShipperByIdApi'
import historyPageApiReal, { getHistoryPagePaymentApi } from './historyPageApi/historyPageApi'

import type { Response } from '@app/types/Response'
import type { ScanOrderData } from '@app/types/ScanOrderData'
import businessAnalyticsApi from './businessAnalyticsApi/businessAnalyticsApi'
import createPackOrderApi from './createOrder/createPackOrderApi'
import createPostOrderApi from './createOrder/createPostOrderApi'
import createReceiptIdApiReal from './receiptApi/createReceiptIdApi'
import createReceiptIdApiStub from './receiptApi/stub'
import createSendOrderApi from './createOrder/createSendOrderApi'
import fetchVersionApi from './FetchVersion/api'
/*
 * fetch billing zone info from dp service.
 * @param country : string
 * @param payload : billingZonePayload ( l1,l2,l3,postcode strings )
 * @return billing zone info (BillingZoneData type)
 */
import getBillingZoneApiReal from './billingZoneApi/billingZoneApi'
import getBillingZoneApiRealStub from './billingZoneApi/stub'
import getCollectOrdersApi from './getCollectOrders/api'
import getCollectOrdersStub from './getCollectOrders/api.stub'
import getConsigneesInfoApiReal from './consigneesApi/consigneeInfoApi'
import getConsigneesInfoApiStub from './consigneesApi/stub'
import getCustomerCollectOrdersApi from './getCustomerCollectOrders/api'
import getCustomerCollectOrdersStub from './getCustomerCollectOrders/api.stub'
import { getDpJobApi } from './getDpJob/api'
import { getDpJobOrdersApi } from './getDpJobOrders/api'
import getDpsPerformanceTieringApi from './getDpsPerformanceTiering/api'
import getDpsPerformanceTieringStub from './getDpsPerformanceTiering/stub'
import getDpsShipperTrendApi from './getDpsShipperTrend/api'
import getDpsShipperTrendStub from './getDpsShipperTrend/stub'
import getDpsShipperVolumesApi from './getDpsShipperVolumes/api'
import getDpsShipperVolumesStub from './getDpsShipperVolumes/stub'
import getDpsVolumeByPerformanceTypeApi from './getDpsVolumeByPerformanceType/api'
import getDpsVolumeByPerformanceTypeStub from './getDpsVolumeByPerformanceType/stub'
import getDpsVolumesByTypeApi from './getDpsVolumesByType/api'
import getDpsVolumesByTypeStub from './getDpsVolumesByType/stub'
import { getHistoryPagePaymentStub } from './historyPageApi/historyPageStub'
import getLodgeInByStatusId from './createOrder/getLodgeInByStatusId'
import { getLoginInfoApi } from './loginApi/getLoginInfoApi'
import getOrderDetailsApiReal from './OrderDetailsApi/getOrderDetailsApi'
import getOrderDetailsApiStub from './OrderDetailsApi/getOrderDetailsApi.stub'
import getOrderEventsApiReal from './OrderDetailsApi/getOrderEventsApi'
import getOrderEventsApiStub from './OrderDetailsApi/getOrderEventsApi.stub'
import getShipperSetupStatusApi from './ShipperApi/api'
import getTrackingOrdersApi from './getTrackingOrders/api'
import { getTrackingOrdersStub } from './getTrackingOrders/stub'
import historyPageApiStub from './historyPageApi/stub'
import postCollectOrderCodApiReal from './postCollectOrderCod/api'
import postCollectOrderCodApiStub from './postCollectOrderCod/stub'
import { postLoginApi } from './loginApi/postLoginApi'
import { postOauthLoginApi } from './loginApi/postOauthLoginApi'
import putCollectOrderApiReal from './putCollectOrder/api'
import putCollectOrderApiStub from './putCollectOrder/stub'
import putCustomerCollectOrdersApi from './putCustomerCollectOrders/api'
import { putOrdersApi } from './putOrders/api'
import scanOrderApiReal from './scanOrder/api'
import scanOrderApiStub from './scanOrder/stub'

const isUsingRealApi = () => process.env.NODE_ENV !== 'test'

const scanOrderApi: (country: string, trackingId: string) => Promise<Response<ScanOrderData>> = isUsingRealApi()
  ? scanOrderApiReal
  : scanOrderApiStub
const createReceiptIdApi = isUsingRealApi() ? createReceiptIdApiReal : createReceiptIdApiStub
const getOrderEventsApi = isUsingRealApi() ? getOrderEventsApiReal : getOrderEventsApiStub
const getConsigneesInfoApi = isUsingRealApi() ? getConsigneesInfoApiReal : getConsigneesInfoApiStub
const getBillingZoneApi = isUsingRealApi() ? getBillingZoneApiReal : getBillingZoneApiRealStub
const getOrderDetailsApi = isUsingRealApi() ? getOrderDetailsApiReal : getOrderDetailsApiStub
const putCollectOrderApi = isUsingRealApi() ? putCollectOrderApiReal : putCollectOrderApiStub
const postCollectOrderCodApi = isUsingRealApi() ? postCollectOrderCodApiReal : postCollectOrderCodApiStub

let getDpsShipperTrend
let getDpsShipperVolumes
let getDpsVolumesByType
let getDpsPerformanceTiering
let getCollectOrders
let getCustomerCollectOrders
let getTrackingOrders
let historyPageApi
let getHistoryPagePayment
let getDpsVolumeByPerformanceType

if (process.env.NODE_ENV !== 'test') {
  getDpsPerformanceTiering = getDpsPerformanceTieringApi
  getDpsShipperTrend = getDpsShipperTrendApi
  getDpsShipperVolumes = getDpsShipperVolumesApi
  getCollectOrders = getCollectOrdersApi
  getCustomerCollectOrders = getCustomerCollectOrdersApi
  getTrackingOrders = getTrackingOrdersApi
  getDpsVolumesByType = getDpsVolumesByTypeApi
  getDpsVolumeByPerformanceType = getDpsVolumeByPerformanceTypeApi
  historyPageApi = historyPageApiReal
  getHistoryPagePayment = getHistoryPagePaymentApi
} else {
  getDpsPerformanceTiering = getDpsPerformanceTieringStub
  getDpsShipperTrend = getDpsShipperTrendStub
  getDpsShipperVolumes = getDpsShipperVolumesStub
  getDpsVolumesByType = getDpsVolumesByTypeStub
  getDpsVolumeByPerformanceType = getDpsVolumeByPerformanceTypeStub
  getCollectOrders = getCollectOrdersStub
  getCustomerCollectOrders = getCustomerCollectOrdersStub
  getTrackingOrders = getTrackingOrdersStub
  historyPageApi = historyPageApiStub
  getHistoryPagePayment = getHistoryPagePaymentStub
}

export { default as dpApi } from './dpApi'
export { default as getHistoryPageApi } from './historyPageApi/historyPageApi'

export {
  postLoginApi,
  postOauthLoginApi,
  createPostOrderApi,
  scanOrderApi,
  createReceiptIdApi,
  createSendOrderApi,
  getLodgeInByStatusId,
  getLoginInfoApi,
  getDpsShipperTrend,
  getDpsShipperVolumes,
  getDpsVolumesByType,
  getDpsPerformanceTiering,
  getCollectOrders,
  getCustomerCollectOrders,
  getTrackingOrders,
  getHistoryPagePayment,
  getDpsVolumeByPerformanceType,
  getOrderEventsApi,
  getConsigneesInfoApi,
  getBillingZoneApi,
  getOrderDetailsApi,
  getDpJobApi,
  getDpJobOrdersApi,
  getShipperByIdApi,
  getShipperByExternalIdApi,
  createPackOrderApi,
  businessAnalyticsApi,
  historyPageApi,
  fetchVersionApi,
  getShipperSetupStatusApi,
  putCollectOrderApi,
  postCollectOrderCodApi,
  putCustomerCollectOrdersApi,
  putOrdersApi
}
