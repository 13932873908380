export default {
  chronoDialiBox: require('./chrono-diali-box.svg'),
  chronoDialiConfirm: require('./chrono-diali-confirm.svg'),
  chronoDialiLogo: require('./chrono-diali-logo.svg'),
  chronoDialiLogoWhite: require('./chrono-diali-logo-white.svg'),
  chronoDialiPencil: require('./chrono-diali-pencil.svg'),
  ninjaExpressLogo: require('./ninja-express-logo.png'),
  ninjaExpressLogoWhite: require('./ninja-express-logo-white.svg'),
  ninjaVanLogo: require('./ninja-van-logo.png'),
  ninjaVanLogoWhite: require('./ninja-van-logo-white.png'),
  ninjaVanPin: require('./icon-150x150.png'),
  ryoJumping: require('./ryo-jumping.png'),
  ryoDancing: require('./ryo-dancing.svg'),
  ryoTyping: require('./ryo-typing.png'),
  ryoWriting: require('./ryo-writing.png'),
  ryoThumbsUp: require('./ryo-thumbs-up.png'),
  ryoCarryingParcels: require('./ryo-carrying-parcels.png'),
  ryoLeavingWithParcel: require('./ryo-leaving-with-parcel.png'),
  uploadFile: require('./upload-file.svg'),
  googleSsoButton: require('./google-sso-button.png'),
  scannerBesideParcel: require('./scanner-beside-parcel.svg'),
  ryoHelpDesk: require('./ryo-helpdesk.svg'),
  ryoOnTruck: require('./ryo-on-truck.svg'),
  ninjaPointPin: require('./ninja-point.svg'),
  expiredParcelsBannerBackground: require('./expired-parcels-banner-bg.svg'),
  ryoBlock: require('./ryo-block.svg'),
  ryoSorry: require('./ryo-sorry.svg'),
  ryoInBox: require('./ryo-in-box.svg'),
  ryoNotice:require('./ryo-notice.svg'),
  enterOtp:require('./enter-otp.png'),
  scannerWithQrCode:require('./scanner-with-qr-code.png'),
}
